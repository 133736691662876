@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap'); */

body {
  margin: 0;
  font-family: Nunito;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Nunito;
}


/* Landing page start */
/* .btn-gredient-green-blue, .btn-gredient-green-blue:hover, .btn-gredient-green-blue:focus {
  @apply bg-gradient-to-r to-green-400 from-blue-500 text-white border-none
}

.btn-width-40 {
  @apply w-40 rounded-md
}

.flex-horizontal-center {
  @apply flex justify-center items-center
} */

.logo {
  margin: 1.25rem 1.563rem 0.688rem 0.875rem;
}

.ns-btn-green {
  background-color: #60c72a;
  height: 2.375rem;
  font-size: 0.938rem;
  font-weight: 600;
}

.ns-container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
/* Landing page end */

/* Ant table roudeded */

.ant-table-container thead > tr > th:first-child {
  border-radius: 1.563rem 0rem 0rem 0rem !important;
} 
.ant-table-container thead > tr > th:last-child {
  border-radius: 0rem 1.563rem 0rem 0rem !important;
}

.components-table-demo-nested .ant-table-expanded-row table:first-child th:first-child,
.components-table-demo-nested .ant-table-expanded-row table:last-child th:last-child
{
  border-radius: inherit!important;
}

.ant-table {
  border-radius: 1.563rem!important;
}

.ant-table-thead > tr > th {
  font-weight: 700;
}

.ant-table-selection-column {
  width: 3.125rem;
}

.ant-table-footer {
  display: flex;
  flex-direction: row-reverse;
  border-radius: 0rem 0rem 1.5625rem 1.5625rem !important;
}

.ant-table-thead tr{
  font-family: 'Nunito';
  font-size: .9375rem;
}
/* Ant table roudeded */